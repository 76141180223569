import axios from '@axios'
import store from '@/store'

export default {
  async postRolData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/getEmpresaRoles`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: id,
      },
    })
    return response
  },
  async postRolInsert(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/rolInsert`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: formulario.empresa,
        nombre: formulario.nombre,
        estado: formulario.estado,
      },
    })
    return response
  },
  async postRolUpdate(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/rolUpdate`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idRol: formulario.id,
        idEmpresa: formulario.empresa,
        nombre: formulario.nombre,
        estado: formulario.estado,
      },
    })
    return response
  },
  async postRolDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/rolDelete`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idRol: id,
      },
    })
    return response
  },
}
