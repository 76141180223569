<template>
  <div>
    <div class="d-block d-md-none mb-2">
      <b-button
        v-show="$can('rolCrear', 'rolCrear')"
        variant="primary"
        block
        @click="modalRol(0)"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Agregar rol</span>
      </b-button>
    </div>
    <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
      <b-button
        v-show="$can('rolCrear', 'rolCrear')"
        class="mr-50"
        variant="primary"
        @click="modalRol(0)"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Agregar rol</span>
      </b-button>
    </div>
    <div class="d-flex justify-content-between flex-wrap my-2">
      <!-- filter -->
      <b-form-group
        label="Buscador"
        label-cols-sm="3"
        label-align-sm="left"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="buscadorRol"
            placeholder="Buscador"
            type="search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!buscadorRol"
              @click="buscadorRol = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de roles">
          <b-row>
            <b-table
              responsive
              striped
              hover
              show-empty
              bordered
              sticky-header
              :small="true"
              :items="itemsRol"
              :fields="fieldsRol"
              :filter="buscadorRol"
              :filter-included-fields="filterOnRol"
            >
              <template #empty>
                <h3 class="text-center my-2">
                  No tiene información para mostrar
                </h3>
              </template>
              <template #emptyfiltered>
                <h3 class="text-center my-2">
                  No se encontro ningun resultado
                </h3>
              </template>
              <template #head()="scope">
                <div class="text-nowrap">
                  {{ scope.label }}
                </div>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">{{ data.value }}</span>
              </template>
              <template #cell(acciones)="row">
                <b-button
                  v-show="$can('rolActualizar', 'rolActualizar')"
                  :id="`modalRol${row.item.id}`"
                  variant="gradient-success"
                  class="btn-icon mr-50"
                  size="sm"
                  @click="modalRol(row.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-show="$can('rolEliminar', 'rolEliminar')"
                  :id="`eliminarRol${row.item.id}`"
                  variant="gradient-danger"
                  class="btn-icon"
                  size="sm"
                  @click="eliminarRol(row.item.id)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-tooltip
                  :target="`modalRol${row.item.id}`"
                  title="Editar rol"
                />
                <b-tooltip
                  :target="`eliminarRol${row.item.id}`"
                  title="Eliminar rol"
                />
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="popupAgregarRol"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="'lg'"
      :title="tituloRol"
      @hidde="limpiarRol()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              variant="primary"
              class="ml-1"
              @click="registrarRol()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarRol()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="empresa"
            label="Empresa"
          >
            <b-form-select
              id="empresa"
              ref="empresa"
              v-model="formRol.empresa"
              :options="optionsEmpresa"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="filterRol">
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="formRol.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="estado"
            label="Estado *"
          >
            <validation-provider
              #default="{ errors }"
              mode="passive"
              name="estado"
              rules="required"
            >
              <b-form-select
                id="estado"
                ref="estado"
                v-model="formRol.estado"
                :options="optionsEstado"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BInputGroup,
  BTable,
  BInputGroupAppend,
  BFormInput,
  BTooltip,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import rolService from '@/service/rolService'
import empresaService from '@/service/empresaService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BInputGroup,
    BTable,
    BInputGroupAppend,
    BFormInput,
    BTooltip,
    BFormSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      cargandoRol: false,
      buscadorRol: null,
      filterOnRol: ['id', 'empresa', 'nombre'],
      fieldsRol: [
        {
          label: 'Código',
          key: 'id',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },

        {
          label: 'Empresa',
          key: 'empresa',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombre',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsRol: [],
      popupAgregarRol: false,
      tituloRol: '',
      formRol: {
        id: 0,
        empresa: '',
        nombre: '',
        estado: '1',
      },
      optionsEmpresa: [{ value: null, text: 'Seleccionar empresa' }],
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.obtenerEmpresa()
      await this.obtenerRol()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerEmpresa() {
      this.optionsEmpresa = [{ value: null, text: 'Seleccionar empresa' }]
      const responseEmpresa = await empresaService.postEmpresaUsuarioData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        responseEmpresa.data.data.forEach(element => this.optionsEmpresa.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerRol() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseRol = await rolService.postRolData(this.token, this.userData.companyid)
      if (responseRol.data.data.length > 0) {
        this.itemsRol = responseRol.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async modalRol(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloRol = 'Agregar rol'
        this.formRol = {
          id: 0,
          empresa: this.userData.companyid,
          nombre: '',
          estado: '1',
        }
      } else {
        this.tituloRol = 'Modificar rol'
        const data = this.itemsRol.find(element => element.id === id)
        this.formRol = {
          id: data.id,
          empresa: data.id_empresa,
          nombre: data.nombre,
          estado: data.estado,
        }
      }
      this.popupAgregarRol = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async limpiarRol() {
      this.$refs.filterRol.reset()
      this.formRol = {
        id: 0,
        empresa: '',
        nombre: '',
        estado: '1',
      }
    },
    async eliminarRol(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await rolService.postRolDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Rol eliminado con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerRol()
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async registrarRol() {
      const success = await this.$refs.filterRol.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formRol.id === 0) {
            await rolService.postRolInsert(this.token, this.formRol)
          } else {
            await rolService.postRolUpdate(this.token, this.formRol)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarRol = false
          setTimeout(() => {
            this.obtenerRol()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
  },
}
</script>

  <style>

  </style>
